import './App.css';
import Accordion from 'react-bootstrap/Accordion';
import { BoxArrowUp, Magic, PlusCircleFill } from 'react-bootstrap-icons';

const App = () => {
  const data = [
    {
      question: 'What is the process for denying a criminal record clearance?',
      answer:
        'When an individual applies for a criminal record clearance and is denied, they will be notified of the decision and their right to appeal. If the denial is not based on a conviction, the individual will be notified within five business days and provided with the reasons for denial, a copy of the criminal record clearance application, and information about the appeal process. The subject can also submit any written information they believe is relevant to the investigation or their qualifications.',
    },
    {
      question: 'What are the requirements for removing records for copying?',
      answer:
        'Before removing records for copying, a licensing representative must prepare a list of the records being removed, sign and date the list, and leave a copy with the administrator or designee. This applies to various types of records, including minutes, emergency and health-related information, and client records. The representative should also return the records undamaged and in good order within three business days. In the case of removing a register of current clients, the representative must return it in the same condition it was received.',
    },
    {
      question: 'What are the requirements for a Needs and Services Plan?',
      answer:
        "A Needs and Services Plan is a written plan that identifies the specific needs of an individual and outlines the services and support they will receive. It should be included in the individual's file and made available to the Department for review. The plan may need to be modified based on debriefing meetings, especially after incidents. The plan should also outline strategies for preventing future incidents.",
    },
    {
      question:
        'What are the vendor requirements for the Adult Residential Facility Initial Certification Training Program?',
      answer:
        'Vendors for the Adult Residential Facility Initial Certification Training Program must meet several requirements. These include submitting a complete request for approval, which includes information about the vendor, proposed instructors, localities where the program will be offered, and records maintenance. Upon approval, vendors must maintain records for three years, submit a roster of participants upon request, and allow Department representatives to monitor training. They must also encourage instructors to answer participant questions and provide evaluations for feedback on the program.',
    },
    {
      question:
        "What information is included in a crisis nursery's plan of operation?",
      answer:
        "A crisis nursery's plan of operation must include information about the purpose, methods, and goals of the program, admission policies and procedures, services provided, and the children they serve. It also requires details on administrative organization, staffing plan with qualifications and duties, a staff training plan, and emergency procedures.  Additional information includes details about the physical environment, food service, child discipline methods, and arrangements for children to attend community programs.",
    },
    {
      question:
        "What are the requirements for an Enhanced Behavioral Supports Home's plan of operation?",
      answer:
        "An Enhanced Behavioral Supports Home's plan of operation should include a statement of purpose, admission policies, a description of services, and staffing details, including an organizational chart. It should also outline emergency procedures, procedures for protecting consumer rights, and criteria for exiting the facility. A description of the continuous quality improvement system is also required, outlining how consumers will be supported in making choices, exercising their rights, and having their changing needs addressed. The plan must also include details about medication management, risk management, and staff turnover mitigation strategies.",
    },
    {
      question: 'What are the personnel requirements for a crisis nursery?',
      answer:
        'Crisis nursery personnel must be at least 18 years old and receive training on various aspects of child care. This training should cover program and services, policies and procedures, job descriptions, reporting requirements, child abuse reporting, child development, behavior management, and emergency procedures. The licensee must ensure direct supervision during potentially dangerous activities and maintain specific ratios of adults with valid water-safety certificates for water activities. Volunteers can be utilized but must also be trained.',
    },
    {
      question: 'What are the requirements for foster family agencies?',
      answer:
        'Foster family agencies must provide a comprehensive program statement that outlines the goals of the program, the types of children served, and the services provided to children, certified parents, and foster family home licensees. The program statement must also include a description of emergency response services. A written description of the foster family agency training plan is also required, which should include employee orientations, ongoing training, and training on child abuse identification, prevention, and treatment. The agency must also meet specific application requirements, such as providing financial statements and a plan of operation.',
    },
    {
      question:
        'What are the regulations for the removal and/or discharge of children from group homes?',
      answer:
        'Group homes must develop, maintain, and implement written policies and procedures for removing or discharging children from their care. These procedures should include conducting an analysis of any incidents that led to the removal or discharge, involving a multidisciplinary team in the decision-making process, and providing appropriate documentation for the removal or discharge. The group home must also provide the child with a written explanation of the reasons for the removal or discharge and offer an opportunity to appeal the decision.',
    },
    {
      question:
        'What is the role of the Department in licensing and inspecting community care facilities?',
      answer:
        'The Department plays a key role in licensing and inspecting community care facilities to ensure the health and safety of residents. The Department reviews applications, conducts inspections, investigates complaints, and has the authority to deny or revoke licenses if facilities fail to meet the required standards. They also require facilities to have procedures for responding to incidents and complaints. The Department may also grant exemptions from certain requirements in specific circumstances.',
    },
    {
      question:
        "What information is included in a foster family home's pre-placement questionnaire?",
      answer:
        "The pre-placement questionnaire for a foster family home is used to gather essential information about a child before placement. The questionnaire includes questions about the child's age, grade level, learning disabilities, medications, swimming ability, social skills, and current service needs. Caregivers can use the questionnaire to assess whether they can meet the child's needs and to obtain necessary authorizations for psychotropic medications.",
    },
    {
      question:
        'What are the requirements for a Transitional Housing Placement Program (THPP)?',
      answer:
        'A THPP must have a plan that includes strategies for preteen and older teen foster youth and nonminor dependents. Personnel must receive training on the characteristics of the people served, including training on healthy sexual development, reproductive and sexual health, and contraception methods. The THPP must also provide access to community resources such as mental health, employment, educational, housing, health care, substance use disorder, and reproductive and sexual health care services. Participants should be given medically accurate information about reproductive health care, pregnancy prevention, and the prevention and treatment of sexually transmitted infections.',
    },
    {
      question:
        'What are the personal rights of residents in residential care facilities for the elderly?',
      answer:
        'Residents in residential care facilities for the elderly have several personal rights, including the right to be treated with respect, make personal decisions, manage their finances, and participate in activities. They have the right to privacy, confidentiality, freedom from abuse, and access to medical care. Residents also have the right to be informed about their rights and responsibilities and to file complaints without fear of retaliation.',
    },
    {
      question: 'What are the requirements for a Small Family Home license?',
      answer:
        "Small Family Homes must adhere to specific licensee duties and responsibilities, including providing orientation and support for educational opportunities. They must offer informational brochures and facilitate campus tours for post-secondary and vocational programs. Licensees are required to train on the reasonable and prudent parent standard, which includes understanding child development stages and supporting age-appropriate activities. They also need a plan addressing preteen and older teen foster youth and nonminor dependents' needs, encompassing their sexual and reproductive health.",
    },
    {
      question:
        'What are the requirements for using delayed egress devices in a community care facility?',
      answer:
        "The use of delayed egress devices in community care facilities is subject to specific requirements to ensure resident safety and protect their rights. Facilities must provide staff training on the proper use and operation of these devices, as well as develop procedures for managing residents' lack of hazard awareness and impulse control.  A detailed description of the facility's emergency evacuation procedures is also required. The capacity of the facility cannot exceed six residents, and the use of these devices does not substitute for adequate staffing. Regular emergency drills must be conducted to ensure preparedness.",
    },
    {
      question:
        'What are the continuing education requirements for residential care facility for the elderly administrators?',
      answer:
        'Residential care facility for the elderly administrators need to fulfill continuing education requirements, including completing specific training programs and courses. These programs cover essential areas like resident care, safety, and regulations. Administrators must also pass an exam based on the Core of Knowledge areas specified in the training program. Vendors providing these training programs must adhere to specific requirements, such as offering the full program hours, issuing certificates of completion, and maintaining records.',
    },
    {
      question:
        'What are the reporting requirements for social rehabilitation facilities?',
      answer:
        "Social rehabilitation facilities must report specific incidents to the licensing agency to ensure accountability and timely response. These reportable incidents include deaths, injuries, illnesses, missing clients, medication errors, abuse allegations, and significant events that impact client well-being. The facility must provide detailed information about each incident, including the client's information, the nature of the event, medical findings, and the resolution of the case. This reporting helps the licensing agency monitor the facility's operations and ensure compliance with regulations.",
    },
    {
      question:
        'What are the responsibilities of a temporary manager in a community care facility?',
      answer:
        "A temporary manager in a community care facility assumes responsibility for the facility's operations and management during their appointment. They are responsible for implementing a facility improvement plan to address any deficiencies and ensure compliance with regulations. Temporary managers must possess the necessary qualifications and experience to effectively manage the facility and provide quality care to residents. They work closely with the Department to improve the facility's overall performance and restore its stability.",
    },
    {
      question:
        'What are the requirements for food service in a crisis nursery?',
      answer:
        "Crisis nurseries must meet specific food service requirements to ensure the children's nutritional needs are met and food safety standards are maintained. They must have written menus posted weekly, adhere to proper food storage and preparation practices, and provide age-appropriate meals and snacks. Bottles and nipples must be cleaned and sanitized using approved methods. The Department has the authority to request information about food purchases and usage to verify compliance.",
    },
    {
      question:
        'What are the requirements for providing emergency adult protective services placements in a residential care facility for the elderly?',
      answer:
        "A residential care facility for the elderly can provide emergency adult protective services placements if they obtain approval from the Department. To get approval, the licensee must submit a written request that includes a letter of interest from the county Adult Protective Services (APS) agency and a copy of the agreement outlining each party's responsibilities. The licensee must also demonstrate a local need for emergency shelter services. The licensee must comply with the regulations for residential care facilities for the elderly, even when providing emergency placements.",
    },
    {
      question:
        'What are the requirements for carbon monoxide devices in community care facilities?',
      answer:
        'Carbon monoxide devices are considered vital for protecting residents in community care facilities from carbon monoxide poisoning. These devices should be installed and maintained in accordance with regulations to ensure their effectiveness. The Legislature intends to include information about carbon monoxide devices in the Homeowners’ Guide to Environmental Hazards to raise awareness and promote their use. This highlights the importance of these devices in maintaining a safe environment for residents.',
    },
    {
      question: 'What are the training requirements for staff in group homes?',
      answer:
        "Group home staff must receive comprehensive training on various aspects of child care and development, including child abuse reporting, first aid, CPR, cultural diversity, and child empowerment. Training topics must be appropriate for the client population and services provided by the facility. They should also be trained on the facility's policies and procedures, disaster response procedures, law enforcement contact procedures, and reporting requirements.  A training handbook containing this information must be maintained at the facility and updated as needed.",
    },
  ];

  const resetConversation = () => {
    const dfMessenger = document.querySelector('df-messenger'); // Select the Dialogflow Messenger widget
    dfMessenger.clearStorage(); // Clear the chat conversation
    dfMessenger.startNewSession();
  };

  return (
    <div className="container mt-4">
      <div
        id="offcanvasRight"
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        aria-labelledby="offcanvasRightLabel"
      >
        <nav class="navbar sticky-top">
          <button
            id="offcanvas-close-button"
            type="button"
            className="my-0 btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <button
            type="button"
            className="mx-3 my-0 btn btn-sm btn-light"
            onClick={resetConversation}
          >
            New Conversation
          </button>
        </nav>
        <df-messenger
          project-id="gen-ai-demo-407720"
          agent-id="4846f1ad-8cc3-4882-b512-9000b0987ed9"
          language-code="en"
          max-query-length="-1"
        >
          <df-messenger-chat chat-title="Care Companion Agent"></df-messenger-chat>
        </df-messenger>
      </div>

      <div
        id="offcanvasLeft"
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        aria-labelledby="offcanvasLeftLabel"
      >
        <nav class="navbar sticky-top bg-dark">
          <button
            id="offcanvas-close-button-faq"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <div className="navbar-brand text-light">Title 22 FAQs</div>
        </nav>
        <div className="offcanvas-body">
          <Accordion defaultActiveKey="0">
            {data.map((item, index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>{item.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>

      <div
        id="offcanvasArchitecture"
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        aria-labelledby="offcanvasArchitecture"
      >
        <button
          id="offcanvas-close-button-arc"
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="offcanvas-body">
          <h1 className="text-center">
            Care Companion Demo
            <br />
            Cloud Architecture
          </h1>
          <img
            id="arc-img"
            alt="architecture"
            src="care-companion-architecture.jpg"
            className="mb-4"
          />
          <h1 className="text-center">
            How to add this web app to your Home Screen...
          </h1>
          <p>
            You can add an icon to your iPhone Home Screen for quick access to
            this app by following a few easy steps.
          </p>
          <ol>
            <li className="mb-2">
              Tap <BoxArrowUp /> in the browser address/menu bar.
            </li>
            <li className="mb-2">
              Scroll down the list of options, then tap Add to Home Screen.
              <br />
              <i className="small text-muted">
                If you don't see Add to Home Screen, you can add it. Scroll down
                to the bottom of the list, tap Edit Actions, then tap{' '}
                <PlusCircleFill /> Add to Home Screen.
              </i>
            </li>
            <li>The icon appears only on the device where you add it.</li>
          </ol>
        </div>
      </div>

      <div id="logo-div">
        <span id="logo-helper"></span>
        <img id="logo-img" alt="logo" src="care-companion-logo-home.jpg" />
      </div>

      <div id="button-div">
        <button
          id="faq-button"
          type="button"
          className="mx-2 btn btn-outline-light"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasLeft"
          aria-controls="offcanvasLeft"
        >
          FAQs
        </button>

        <button
          id="chat-button"
          type="button"
          className="btn btn-outline-light"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          Chat
        </button>
      </div>

      <footer>
        <p>
          &copy; 2024 Suitebriar, Inc. &nbsp;
          <Magic
            className="mb-1"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasArchitecture"
            aria-controls="offcanvasArchitecture"
          />
        </p>
      </footer>
    </div>
  );
};

export default App;
